import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { LoadingProvider } from './providers/LoadingContext'; 
import { useLoading } from './hooks/useLoading';
import Loader from './components/Loader';
import AnimatedBackground from './components/AnimatedBackground';
import Footer from './components/Footer';
import Header from './components/Header';
import HeaderMenu from './components/HeaderMenu'; 
import Home from './pages/Home';



// Registros
import RegistrosList from './components/RegistrosList';


// Contacto
import ContactForm from './components/ContactForm';

// Servicios
import Comunicacion from './pages/Comunicacion';
import Seguridad from './pages/Seguridad';
import Cloud from './pages/Cloud';
import Iot from './pages/Iot';

// Cloud
import Msft365 from './pages/cloud/Msft365';
import Omnicanal from './pages/cloud/Omnicanal';


// Comunicacion
import SdWan from './pages/comunicacion/SdWan';
import Pdti from './pages/comunicacion/Pdti';
import MovistarLink from './pages/comunicacion/MovistarLink';
import HardwareParaEmpresas from './pages/comunicacion/HardwareParaEmpresas';
import ContactFormMovistarLink from './components/ContactFormMovistarLink';


// IoT
import ControlDePersonal from './pages/iot/ControlDePersonal';
import GestionDeTareas from './pages/iot/GestionDeTareas';
import Kite from './pages/iot/Kite';
import LocDeFlotas from './pages/iot/LocDeFlotas';
import FacturacionElectronica from './pages/iot/FacturacionElectronica';

// Seguridad
import SeguridadMdm from './pages/seguridad/SeguridadMdm';
import CiberseguridadGestionada from './pages/seguridad/CiberseguridadGestionada';
import Siem from './pages/seguridad/Siem';

// CustomCursor
import CustomCursor from './components/CustomCursor';

// Not Found
import NotFound from './components/NotFound'; 

import './App.css';
import ServiciosProfesionales from './pages/seguridad/ServiciosProfesionales';

function App() {
  return (
    <LoadingProvider>
      <Router>
        <LayoutWithLoader />
      </Router>
    </LoadingProvider>
  );
}

function LayoutWithLoader() {
  const { loading } = useLoading();
  const location = useLocation();

  // Agregamos clase al body segun la ruta
  useEffect(() => {
    const body = document.body;
    if (location.pathname.startsWith('/seguridad')) {
      body.className = 'seguridad';
    } else if (location.pathname.startsWith('/cloud')) {
      body.className = 'cloud';
    } else if (location.pathname.startsWith('/comunicacion-y-conectividad')) {
      body.className = 'comunicacion';
    } else if (location.pathname.startsWith('/iot')) {
      body.className = 'iot';
    } else if (location.pathname.startsWith('/registros')) {
      body.className = 'registros';
    } else if (location.pathname.startsWith('/contacto')) {
      body.className = 'contacto';
    } else {
      body.className = 'default';
    }
    
    return () => {
      body.className = '';
    };
  }, [location]);

  const getHeader = () => {
    if (location.pathname === '/') {
      return <Header />; 
    } else {
      return <HeaderMenu />; 
    }
  };

  return (
    <>
      {loading && <Loader />}
      {getHeader()}
      
        <CustomCursor key={location.pathname} />
        <AnimatedBackground />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/comunicacion-y-conectividad" element={<Comunicacion />} />
          <Route path="/seguridad" element={<Seguridad />} />
          <Route path="/cloud" element={<Cloud />} />
          <Route path="/iot" element={<Iot />} />
          <Route path="/contacto" element={<ContactForm />} />

          
          <Route path="/cloud/omnicanal" element={<Omnicanal />} />
          <Route path="/cloud/msft-365" element={<Msft365 />} />

          <Route path="/seguridad/seguridad-mdm" element={<SeguridadMdm />} />
          <Route path="/seguridad/ciberseguridad-gestionada" element={<CiberseguridadGestionada />} />
          <Route path="/seguridad/siem" element={<Siem />} />
          <Route path="/seguridad/servicios-profesionales" element={<ServiciosProfesionales />} />

          <Route path="/comunicacion-y-conectividad/sd-wan" element={<SdWan />} />
          <Route path="/comunicacion-y-conectividad/pdti" element={<Pdti />} />
          <Route path="/comunicacion-y-conectividad/movistar-link" element={<MovistarLink />} />
          <Route path="/comunicacion-y-conectividad/hardware-para-empresas" element={<HardwareParaEmpresas />} />


          <Route path="/comunicacion-y-conectividad/movistar-link/contacto" element={<ContactFormMovistarLink />} />
          
          <Route path="/iot/kite" element={<Kite />} />
          <Route path="/iot/loc-de-flotas" element={<LocDeFlotas />} />
          <Route path="/iot/control-de-personal" element={<ControlDePersonal />} />
          <Route path="/iot/gestion-de-tareas" element={<GestionDeTareas />} />
          <Route path="/iot/facturacion-electronica" element={<FacturacionElectronica />} />

          <Route path="/registros" element={<RegistrosList />} />

          <Route path="*" element={<NotFound />} />

        </Routes>
      <Footer/>
    </>
  );
}

export default App;
