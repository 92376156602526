import React, { useEffect, useRef } from 'react';
import TitleService from '../../components/TitleService';
import SubHeader from '../../components/SubHeader';
import MarqueeText from '../../components/MarqueeText';
import BeneficiosComunicacion from '../../components/BeneficiosComunicacion';
import BeneficiosContainer from '../../components/BeneficiosContainer';
import WhyMovistar from '../../components/WhyMovistar';
import MarcasSeguridad from '../../components/MarcasSeguridad';
import anime from 'animejs/lib/anime.es.js';

import logocomunicacion from '../../assets/images/comunicacion/logocomunicacion.png';

import path_to_icon1 from '../../assets/images/comunicacion/hardware/path_to_icon1.png';
import path_to_icon2 from '../../assets/images/comunicacion/hardware/path_to_icon2.png';
import path_to_icon3 from '../../assets/images/comunicacion/hardware/path_to_icon3.png';
import path_to_icon4 from '../../assets/images/comunicacion/hardware/path_to_icon4.png';
import path_to_icon5 from '../../assets/images/comunicacion/hardware/path_to_icon5.png';
import path_to_icon6 from '../../assets/images/comunicacion/hardware/path_to_icon6.png';

import marca1 from '../../assets/images/marcas/logo_apc.png';
import marca2 from '../../assets/images/marcas/logo_cisco.png';
import marca3 from '../../assets/images/marcas/logo_lenovo.png';
import marca4 from '../../assets/images/marcas/logo_dell.png';
import marca5 from '../../assets/images/marcas/logo_hewlett.png';
import marca6 from '../../assets/images/marcas/logo_Huawei.png';
import marca7 from '../../assets/images/marcas/logo_cisco_meraki.png';



const MovistarLink = () => {
  const buttonRef = useRef(null);

  useEffect(() => {
    anime({
      targets: buttonRef.current,
      translateY: [-50, 0],
      opacity: [0, 1],
      delay: 2500,
      duration: 1000,
      easing: 'easeOutExpo',
    });
  }, []);

  const benefits = [
    {
      icon: path_to_icon1,
      text: '<b>Marcas lideres</b> ofrecemos hardware de marcas reconocidas, garantizando calidad y rendimiento superior.'
    },
    {
      icon: path_to_icon2,
      text: '<b>Asesoramiento especializado</b> brindamos soluciones adaptadas a las necesidades de tu negocio.'
    },
    {
      icon: path_to_icon3,
      text: '<b>Precios competitivos</b> accedé a tecnología de avanzada a precios accesibles.'
    }
  ];

  const reasons = [
    {
      icon: path_to_icon4,
      text: '<b>Atención personalizada</b> con un equipo de expertos 24/7.'
    },
    {
      icon: path_to_icon5,
      text: '<b>Opciones de financiación</b> flexibles para facilitar tu compra.'
    },
    {
      icon: path_to_icon6,
      text: '<b>Asistencia técnica especializada</b> para asegurar el óptimo rendimiento de tus equipos.'
    }
  ];

  const marcas = [
    {
      icon: marca1,
    },
    {
      icon: marca2,
    },
    {
      icon: marca3,
    },
    {
      icon: marca4,
    },
    {
      icon: marca5,
    },
    {
      icon: marca6,
    },
    {
      icon: marca7,
    }
  ];



  return (
    <div className='container no-video'>
      <TitleService
        linkTo="/"
        imgSrc={logocomunicacion}
        imgAlt="Logo de seguridad digital"
        description="Potenciá tu empresa implementando herramientas de última generación para estar siempre conectado con tus clientes."
      />

      <SubHeader
        title="<b>Hardware para empresas</b>"
        subtitle1="Potenciá tu empresa con hardware de alto rendimiento."
        subtitle2="Computadoras, servidores, periféricos y redes diseñados para optimizar la productividad y fortalecer tu infraestructura tecnológica. Confiabilidad y rendimiento superior para tu negocio."
      />

      <MarqueeText text="Hardware para empresas" />

      <BeneficiosComunicacion/>

      <BeneficiosContainer benefits={benefits} />

      <WhyMovistar reasons={reasons} />

      <MarcasSeguridad marcas={marcas} />
      
    </div>
  );
};

export default MovistarLink;
