import React from 'react';

console.log(process.env.REACT_APP_API_URL)

const BotonExcel = () => {
  const handleDownload = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/export-excel`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Error al descargar el archivo');
      }

      // Convertir la respuesta en un blob (archivo binario)
      const blob = await response.blob();

      // Crear una URL de descarga para el archivo
      const url = window.URL.createObjectURL(blob);

      // Crear un enlace invisible y simular clic
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Registros.xlsx'; 
      document.body.appendChild(a);
      a.click();

      // Limpiar el objeto URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
    }
  };

  return (
    <button onClick={handleDownload} style={{ padding: '10px', fontSize: '16px', cursor: 'pointer' }}>
      📥 Descargar Excel
    </button>
  );
};

export default BotonExcel;
