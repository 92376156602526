import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react'; // Iconos para mostrar y ocultar la contraseña

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // Lista de contraseñas válidas para los usuarios:
  const validPasswords = [
    'V4n!naL@in0',  // vanina.laino
    'Alv@r0B#eno1', // alvaro.bueno
    'C@rl4V@lverd3', // carla.valverde
    'Clavered1!', // vml
    'Je@nJ#st0m@rq#ez', // jean.justomarquez
    'Gust@v0M@l@ch0wsk2' // gustavo.malachowski
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validPasswords.includes(password)) {
      onLogin();
    } else {
      setError('Contraseña incorrecta');
    }
  };

  return (
    <div className='login'>
      <h2>Acceso a registros del formulario</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Introduce tu contraseña'
            style={{ paddingRight: '40px' }} // Espacio para el icono
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            style={{
              position: 'absolute',
              right: '10px',
              background: 'none',
              border: 'none',
              cursor: 'pointer'
            }}
          >
            {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
          </button>
        </div>
        <button type='submit' className="botonEntrar">Entrar</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    </div>
  );
};

export default Login;
