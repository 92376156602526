import React, { useEffect, useState } from 'react';
import Login from './Login';
import BotonExcel from './BotonExcel';


const RegistrosList = () => {
  const [files, setFiles] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (isAuthenticated) {
      fetch(`${apiUrl}/registros`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setFiles(data);
        })
        .catch(error => {
          console.error('Error al recuperar archivos:', error);
        });
    }
  }, [isAuthenticated, apiUrl]);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleDelete = (id, source) => {
    fetch(`${apiUrl}/registros/${id}`, { method: 'DELETE' })
      .then(response => {
        if (response.ok) {
          setFiles(files.filter(file => file._id !== id || file.source !== source));
        } else {
          console.error('Error al eliminar el registro.');
        }
      })
      .catch(error => {
        console.error('Error al realizar la solicitud de eliminación:', error);
      });
  };

  if (!isAuthenticated) {
    return <Login onLogin={handleLogin} />;
  }

  // Función para formatear la fecha en DD-MM-AAAA
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() retorna de 0-11
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Función para agrupar registros por fecha en formato DD-MM-AAAA
  const groupByDate = (records) => {
    const grouped = {};
    records.forEach(file => {
      const formattedDate = formatDate(file.uploadDate); // Aplicar formato
      if (!grouped[formattedDate]) {
        grouped[formattedDate] = [];
      }
      grouped[formattedDate].push(file);
    });

    // Ordenar las fechas de más reciente a más antigua
    return Object.keys(grouped)
      .sort((a, b) => {
        const dateA = new Date(a.split('-').reverse().join('-')); // Convertir a AAAA-MM-DD
        const dateB = new Date(b.split('-').reverse().join('-'));
        return dateB - dateA; // Orden descendente
      })
      .reduce((acc, key) => {
        acc[key] = grouped[key];
        return acc;
      }, {});
  };

  const groupedFiles = groupByDate(files);

  return (
    <div className='registrados'>
      <h1>Consola de correos</h1>

      <div className='contenedorbotonexcel'>
        <BotonExcel />
      </div>

      {Object.keys(groupedFiles).length === 0 && <p>No se encontraron registros.</p>}
      {Object.entries(groupedFiles).map(([date, records]) => (
        <div key={date} className='cajaregistrofecha'>
          <div className='registrofecha'>
            <p>Fecha:</p>
            <h2>{date}</h2>
          </div>  
          <ul>
            {records.map(file => (
              <li key={file._id} className={`box-default ${file.source === 'MovistarLink' ? 'movistar-link' : 'movistar'}`}>
                {file.source === 'Movistar' && (
                  <>
                    <p><strong>Nombre:</strong> {file.name}</p>
                    <p><strong>Celular:</strong> {file.phone}</p>
                    <p><strong>Correo:</strong> {file.email}</p>
                    <p><strong>Servicio:</strong> {file.selectedOption}</p>
                    <p><strong>RUT:</strong> {file.rut ? file.rut : 'Sin RUT'}</p>
                    <p><strong>Comentarios:</strong> {file.comments ? file.comments : 'Sin comentarios'}</p>
                  </>
                )}

                {file.source === 'MovistarLink' && (
                  <>
                    <p><strong>Nombre:</strong> {file.name}</p>
                    <p><strong>Celular:</strong> {file.phone}</p>
                    <p><strong>Correo:</strong> {file.email}</p>
                    <p><strong>RUT:</strong> {file.rut ? file.rut : 'Sin RUT'}</p>
                  </>
                )}
                <button onClick={() => handleDelete(file._id, file.source)} className="btn">Borrar</button>
                <p><em>Fuente: {file.source}</em></p>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default RegistrosList;
