import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';  
import anime from 'animejs/lib/anime.es.js';
import fondo_contacto_desktop from '../assets/images/butt_iot_desktop.png';
import ReCaptchaComponent from './ReCaptchaComponent'; 


const ContactForm = () => {
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [recaptchaToken, setRecaptchaToken] = useState(null); 
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [comments, setComments] = useState("");
    const [wordCount, setWordCount] = useState(0);
    const [selectedOption, setSelectedOption] = useState(""); // Estado para el select


    useEffect(() => {
        anime({
            targets: '#aplicar h1, h2, p, #registrationForm .form-group',
            translateY: [-50, 0],
            opacity: [0, 1],
            easing: 'easeOutExpo',
            duration: 1000
        });

        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleCommentsChange = (event) => {
        const input = event.target.value;
        const words = input.trim().split(/\s+/);
        const count = words.length;

        if (count <= 150) {
            setComments(input);
            setWordCount(count);
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Límite de palabras excedido',
                text: 'El comentario no debe exceder las 150 palabras',
            });
        }
    };

    const handleReCaptchaVerify = (token) => {
        setRecaptchaToken(token); 
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!recaptchaToken) {
            Swal.fire({
                icon: 'error',
                title: 'Error de reCAPTCHA',
                text: 'Por favor, verifica que no eres un robot.',
            });
            return;
        }

        setIsSubmitting(true);
    
        const formData = {
            name: event.target.name.value.trim(),
            phone: event.target.phone.value.trim(),
            email: event.target.email.value.trim(),
            selectedOption: selectedOption, 
            comments: comments.trim(),
            recaptchaToken: recaptchaToken 
        };

        // Si el campo rut tiene algún valor, lo añadimos al formData
        const rutValue = event.target.rut.value.trim();
        if (rutValue) {
            formData.rut = rutValue;
        }
    
        if (!formData.name || formData.name.length < 3) {
            Swal.fire({
                icon: 'error',
                title: 'Error en el formulario',
                text: 'El nombre debe tener al menos 3 caracteres',
            });
            setIsSubmitting(false);
            return;
        }
    
        const phonePattern = /^[0-9]{9}$/; 
        if (!phonePattern.test(formData.phone)) {
            Swal.fire({
                icon: 'error',
                title: 'Error en el formulario',
                text: 'El número de teléfono debe ser de 9 dígitos',
            });
            setIsSubmitting(false);
            return;
        }
    
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
        if (!emailPattern.test(formData.email)) {
            Swal.fire({
                icon: 'error',
                title: 'Error en el formulario',
                text: 'Por favor ingrese un correo electrónico válido',
            });
            setIsSubmitting(false);
            return;
        }

        if (wordCount > 150) {
            Swal.fire({
                icon: 'error',
                title: 'Error en el formulario',
                text: 'El comentario no debe exceder las 150 palabras',
            });
            setIsSubmitting(false);
            return;
        }
    
        const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:5000/api";
        
        axios.post(`${apiUrl}/upload`, formData)
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: 'Formulario enviado con éxito',
                    showConfirmButton: false,
                    timer: 1500
                });
    
                event.target.reset();
                setRecaptchaToken(null);
                setSelectedOption("");
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error enviando el formulario',
                    text: error.response ? error.response.data.message : error.message,
                });
                console.error('Error enviando el formulario:', error.response || error);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const containerStyle = {
        display: 'flex',
        maxWidth: '1200px',
        margin: 'auto',
        padding: isMobile ? '20px 20px 60px 20px' : '20px',
        color: '#fff',
        flexDirection: isMobile ? 'column' : 'row', 

    };

    const textStyle = {
        flex: '1',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px',
        marginRight: '20px',
        justifyContent: 'flex-start',
    };

    const formContainerStyle = {
        flex: '1',
        padding: '20px',
        borderRadius: '10px',
    };

    const headingStyle = {
        textAlign: 'left',
        marginBottom: '20px',
        color: '#fff',
        marginTop: '0px',

    };

    const headingStyle2 = {
        marginTop: '0px',
        marginBottom: '20px',
        color: '#fff',
        fontSize: '2.5em',
    };

    const labelStyle = {
        color: '#fff',
        fontWeight: 'bold',
    };

    const inputStyle = {
        borderRadius: '5px',
        border: '1px solid #ddd',
        backgroundColor: '#cacaca3b',
        color: '#fff',
        padding: '10px',
        fontSize: '16px',
        width: '100%',
        marginBottom: '20px',
        marginRight: '10px',
        boxSizing: 'border-box',
        outline: 'none'
    };

    const textareaStyle = {
        ...inputStyle,
        height: '100px',
        resize: 'none',
    };

    const selectStyle = {
        borderRadius: "5px",
        border: "1px solid #ddd",
        backgroundColor: "#cacaca3b",
        color: "#fff",
        padding: "10px",
        fontSize: "16px",
        width: "100%",
        marginBottom: "20px",
        marginRight: "10px",
        boxSizing: "border-box",
        appearance: "none", // Oculta la flecha predeterminada
      
        /* Ajustar la flecha */
        backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 24 24%22 fill=%22none%22 stroke=%22white%22 stroke-width=%222%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22%3E%3Cpath d=%22M6 9l6 6 6-6%22/%3E%3C/svg%3E")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right 10px center", 
        backgroundSize: "20px", 
        paddingRight: "40px",
        outline: 'none'
      };   

    const buttonStyle = {
        minWidth: '200px',
        padding: '10px',
        fontSize: '20px',
        borderRadius: '5px',
        color: 'white',
        float: 'left',
        transition: 'background-color 0.3s ease, opacity 0.3s ease',
        cursor: isSubmitting ? 'not-allowed' : 'pointer',
        backgroundImage: `url(${fondo_contacto_desktop})`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        opacity: isSubmitting ? 0.5 : 1, 
        filter: 'grayscale(100%)'
    };

    return (
        <div id="aplicar" style={containerStyle} className="mt-5">
            <div style={textStyle}>
                <h1 style={headingStyle}>Quiero información</h1>
                <h2 style={headingStyle2}>Soluciones digitales a la medida de tu empresa</h2>
                <p>Dejanos tus datos y un asesor te contactará para brindarte una solución a tu medida.</p>
            </div>

            <div style={formContainerStyle}>
                <form id="registrationForm" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name" style={labelStyle}>Nombre</label>
                        <input type="text" className="form-control" id="name" name="name" style={inputStyle} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone" style={labelStyle}>Celular</label>
                        <input 
                            type="tel" 
                            className="form-control" 
                            id="phone" 
                            name="phone" 
                            style={inputStyle} 
                            required 
                            maxLength="9" 
                            pattern="\d{9}" 
                            inputMode="numeric"
                            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email" style={labelStyle}>Correo Electrónico</label>
                        <input type="email" className="form-control" id="email" name="email" style={inputStyle} required />
                    </div>

                    <div className="form-group">
                        <label htmlFor="selectedOption" style={labelStyle}>Seleccione un servicio</label>
                        <select className="form-control" id="selectedOption" name="selectedOption" style={selectStyle} value={selectedOption} onChange={handleSelectChange} required>
                            <option value="">Seleccione una opción</option>
                            {["Control de personal", "Facturación electrónica", "Gestión de tareas", "Hardware para empresas", "Kite", "Localización de flotas", "Movistar Omnicanal", "Movistarlink", "Office", "PDTI", "SDWAN Movistar", "Seguridad gestionada", "Seguridad MDM", "Servicios profesionales", "SIEM"].map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="rut" style={labelStyle}>RUT</label>
                        <input 
                        type="tel" 
                        className="form-control" 
                        id="rut" 
                        name="rut" 
                        style={inputStyle} 
                        maxLength="12" 
                        pattern="\d{12}" 
                        inputMode="numeric"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="comments" style={labelStyle}>Comentarios</label>
                        <textarea
                            className="form-control"
                            id="comments"
                            name="comments"
                            style={textareaStyle}
                            value={comments}
                            onChange={handleCommentsChange}
                            placeholder="Escribe tus comentarios aquí (máximo 150 palabras)"
                            maxLength={150 * 6}
                        />
                    </div>            

                    <ReCaptchaComponent onVerify={handleReCaptchaVerify} />
            
                    <button type="submit" id="aplicarbutton" className="boton btn btn-primary" style={buttonStyle} disabled={isSubmitting}>Aplicar</button>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;
